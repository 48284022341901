import { request } from './request'

export function getBespokeDetail(page, limit, begin, end, amount, status, operator, method, sort) {
    return request({
        url: "/investSelf",
        params: {
            page,
            limit,
            begin,
            end,
            amount,
            status, operator, method, sort
        }
    })
}
export function bespokeDetail(id, title, amount, fundPwd,begin,end) {
    return request({
        method: "post",
        url: "/investSelf",
        data: {
            id,
            title,
            amount,
            fundPwd,
            begin,
            end
        }
    })
}


export function bespokeDetails(id) {
    return request({
        method: "get",
        url: "/investSelf/detail",
        params: {
            id
        }
    })
}