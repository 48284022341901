<template>
  <div class="home">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item class="dangq">当前位置：</el-breadcrumb-item>
      <el-breadcrumb-item class="dangq"
        ><router-link to="/">汇成国际</router-link></el-breadcrumb-item
      >
      <el-breadcrumb-item class="dangq"
        ><router-link to="#">VIP定制</router-link></el-breadcrumb-item
      >
    </el-breadcrumb>

    <div class="cust_flex">
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="操盘手:">
            <el-input v-model="formInline.user" placeholder=""></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="link">
        <div :class="link == 1 ? 'link1 link2' : 'link1'" @click="links1">
          我要定制
        </div>
        <div :class="link == 2 ? 'link1 link2' : 'link1'" @click="links2">
          操作中定制
        </div>
        <div :class="link == 3 ? 'link1 link2' : 'link1'" @click="links3">
          已清盘定制
        </div>
      </div>
    </div>

    <div class="conts">
      <div class="hengx"></div>
      <div class="conts_nei">
        <!-- title -->
        <div class="conts_title" v-if="link == 1">
          <div class="titles">操盘手</div>
          <div class="titles" @click="years1">
            预付年化收益率
            <div class="sort-img">
              <img ref="s1" :src="top0" alt="" />
              <img ref="s2" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles" @click="fengxian">
            预付风险金比例
            <div class="sort-img">
              <img ref="s3" :src="top1" alt="" />
              <img ref="s4" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles">操作</div>
        </div>
        <div class="conts_title" v-else-if="link == 2">
          <div class="titles" style="width: 18%">定制名称</div>
          <div class="titles" style="width: 8%">操盘手</div>
          <div class="titles" style="width: 18%" @click="years2">
            定制年化收益率
            <div class="sort-img">
              <img ref="c1" :src="top1" alt="" />
              <img ref="c2" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles" style="width: 18%" @click="jingzhi1">
            当前产品净值(元)
            <div class="sort-img">
              <img ref="c3" :src="top1" alt="" />
              <img ref="c4" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles" style="width: 20%" @click="jine1">
            定制金额(元)
            <div class="sort-img">
              <img ref="c5" :src="top1" alt="" />
              <img ref="c6" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles" style="width: 18%" @click="dq">
            截止到期时间
            <div class="sort-img">
              <img ref="c7" :src="top1" alt="" />
              <img ref="c8" :src="down0" alt="" />
            </div>
          </div>
        </div>
        <div class="conts_title" v-else-if="link == 3">
          <div class="titles" style="width: 18%">定制名称</div>
          <div class="titles" style="width: 8%">操盘手</div>
          <div class="titles" style="width: 18%" @click="years3">
            定制年化收益率
            <div class="sort-img">
              <img ref="q1" :src="top1" alt="" />
              <img ref="q2" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles" style="width: 18%" @click="jingzhi2">
            当前产品净值(元)
            <div class="sort-img">
              <img ref="q3" :src="top1" alt="" />
              <img ref="q4" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles" style="width: 20%" @click="jine2">
            定制金额(元)
            <div class="sort-img">
              <img ref="q5" :src="top1" alt="" />
              <img ref="q6" :src="down1" alt="" />
            </div>
          </div>
          <div class="titles" style="width: 18%" @click="qb">
            清盘时间
            <div class="sort-img">
              <img ref="q7" :src="top0" alt="" />
              <img ref="q8" :src="down1" alt="" />
            </div>
          </div>
        </div>
        <!-- 循环 -->
        <div class="lli" v-if="link == 1">
          <div
            class="conts_title cont_list"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="titles zicolor">{{ item.name }}</div>
            <div class="titles zicolor redcolor">
              {{ item.annual_profit / 100 }}%+{{ item.float_profit / 100 }}%
            </div>
            <div class="titles zicolor">{{ item.risk_fund_rate / 100 }}%</div>
            <div
              class="titles zicolor lancolor"
              @click.stop="selected(item.id)"
            >
              选定
            </div>
          </div>
        </div>
        <!-- 操作 -->
        <div class="lli" v-else-if="link == 2">
          <div
            class="conts_title cont_list"
            v-for="(item, index) in list"
            :key="index"
            @click="
              $router.push({
                path: '/customwinding',
                query: { id: item.id },
              })
            "
          >
            <div class="titles zicolor" style="width: 18%">
              {{ item.title }}
            </div>
            <div class="titles zicolor" style="width: 8%">{{ item.name }}</div>
            <div class="titles zicolor redcolor" style="width: 18%">
              {{ item.annual_profit / 100 }}%+{{ item.float_profit / 100 }}%
            </div>
            <div class="titles zicolor" style="width: 18%">
              {{ item.final_amount / 100 }}
            </div>
            <div class="titles zicolor" style="width: 20%">
              {{ item.amount / 100 }}
            </div>
            <div class="titles zicolor" style="width: 18%">
              <!-- {{ (item.end_at * 1000) | format("YYYY-MM-DD HH:mm") }} -->
              <van-count-down
                :time="item.end_at * 1000 - date"
                format="DD天HH小时mm分钟"
              />
            </div>
          </div>
        </div>
        <div class="lli" v-else-if="link == 3">
          <div
            class="conts_title cont_list"
            v-for="(item, index) in list"
            :key="index"
            @click="
              $router.push({
                path: '/customwinding',
                query: { id: item.id },
              })
            "
          >
            <div class="titles zicolor" style="width: 18%">
              {{ item.title }}
            </div>
            <div class="titles zicolor" style="width: 8%">{{ item.name }}</div>
            <div class="titles zicolor redcolor" style="width: 18%">
              {{ item.annual_profit / 100 }}%+{{ item.float_profit / 100 }}%
            </div>
            <div class="titles zicolor" style="width: 18%">
              {{ item.final_amount / 100 }}
            </div>
            <div class="titles zicolor" style="width: 20%">
              {{ item.amount / 100 }}
            </div>
            <div class="titles zicolor" style="width: 18%">
              {{ (item.end_at * 1000) | format("YYYY-MM-DD") }}
            </div>
          </div>
        </div>
        <!-- 循环结束 -->

        <el-pagination
          class="page"
          :page-size="limit"
          background
          layout="prev, pager, next"
          :total="count"
          :current-page="page"
          @current-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 遮罩层 -->
    <div class="selected_zhe" v-show="selected_zhe"></div>
    <!-- 进入页面就显示 我要定制 -->
    <div class="selected_tan" v-show="selected_tan">
      <div class="tan_center">
        <div class="me_ding">我要定制</div>
        <div class="tan_flex tan_flex_top">
          <div class="me_ding_jin">定制金额：</div>
          <div class="block">
            <!-- @input="formatValue(valueMoney)" -->
            <input
              class="tan_ku"
              v-model="valueMoney"
              type="text"
              placeholder="请输入金额"
              autocomplete="off"
            />
          </div>
          <div class="me_ding_jin">元</div>
        </div>
        <div class="tan_flex">
          <div class="me_ding_jin">开始时间：</div>
          <div class="block">
            <el-date-picker
              v-model="valueStart"
              type="date"
              placeholder="选择日期"
              @change="chooseStart"
              :editable="false"
              :picker-options="pickerOptions0"
              :default-value="today"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="tan_flex">
          <div class="me_ding_jin">结束时间：</div>
          <div class="block">
            <el-date-picker
              v-model="valueEnd"
              type="date"
              placeholder="选择日期"
              @change="chooseEnd"
              :editable="false"
              :picker-options="pickerOptions1"
              :default-value="mtoday"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="next" @click="next">下一步</div>
        <div class="explain">
          <div class="explain_title">说明:</div>
          <div class="explain_nei">
            <p>1.定制产品为垫本预付+浮动类型，风险由操盘手承担；</p>
            <p>2.定制产品定制金额最低5万精确到元为单位；</p>
            <p>3.定制产品开始时间结束时间必须选在交易日(周一到周五)；</p>
            <p>4.定制信息不得透露联系方式等无关信息。</p>
          </div>
        </div>
      </div>
      <div class="close-icon">
        <img
          @click="
            selected_zhe = false;
            selected_tan = false;
          "
          src="../../assets/img/close2.png"
          alt=""
        />
      </div>
    </div>
    <!-- 设定产品名称 -->
    <div class="set_name" v-show="setNames">
      <div class="set_title">设定产品名称</div>
      <div class="inp_name">请输入产品名(不超过 <span>20</span> 字):</div>
      <input
        type="text"
        placeholder="请输入产品名"
        v-model="setName"
        class="setName"
        maxlength="20"
        autocomplete="off"
      />
      <div class="set_btn">
        <div class="set_no" @click="set_no">取消</div>
        <div class="set_ok" @click="set_ok">确定</div>
      </div>
    </div>
    <!-- 定制信息 -->
    <div class="custom" v-show="custom">
      <div class="custom_center">
        <div class="custom_title">定制信息</div>
        <div class="custom_list">
          <div class="custom_name">定制名称:</div>
          <div class="custom_inp">{{ setName }}</div>
        </div>
        <div class="custom_list">
          <div class="custom_name">定制金额(元):</div>
          <div class="custom_inp custom_red">{{ valueMoney }}</div>
        </div>
        <div class="custom_list">
          <div class="custom_name">定制周期(天):</div>
          <div class="custom_inp custom_red">{{ info.days }}</div>
        </div>
        <div class="custom_list">
          <div class="custom_name">到期时间:</div>
          <div class="custom_inp">
            {{ (info.end_at * 1000) | format("YYYY-MM-DD HH:mm") }}
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">预期年化收益率:</div>
          <div class="custom_inp">
            {{ info.annual_profit / 100 }}% + {{ info.float_profit / 100 }}%
          </div>
        </div>
        <div class="custom_list">
          <div class="custom_name">投资者到期收益(元):</div>
          <div class="custom_inp custom_red">{{ info.profit / 100 }}</div>
        </div>
        <div class="custom_heng"></div>
        <div class="investment">
          <div class="investment_one">定制各方预计投入金额(元):</div>
          <div class="investment_two">
            <div class="two_left">{{ names }}:</div>
            <div class="two_right">{{ valueMoney }}(投资金)</div>
          </div>
          <div class="investment_two">
            <div class="two_left">{{ info.name }}:</div>
            <div class="two_right">{{ info.risk / 100 }}(风险金)</div>
          </div>
          <div class="investment_two">
            <div class="two_left"></div>
            <div class="two_right">{{ info.profit / 100 }}(预付收益)</div>
          </div>
        </div>
        <div class="determine" @click="determine">确定</div>
      </div>
      <img
        src="../../assets/img/close2.png"
        class="close2"
        @click="close"
        alt=""
      />
    </div>
    <!-- 资金密码 -->
    <div class="capital" v-show="capital">
      <div class="fundPassword_title">资金密码</div>
      <div class="fundPassword_list">
        <div class="fundPassword_inp">资金密码：</div>
        <input
          class="fundPassword_pwd"
          type="password"
          v-model="password"
          placeholder="请输入资金密码"
          autocomplete="off"
        />
      </div>
      <router-link to="/vipCenter/changeMoneyPwd" class="fundPassword_wang">
        <img class="fundPassword_tu" src="../../assets/img/key2.png" alt="" />
        <div class="fundPassword_tiao">忘记密码</div>
      </router-link>
      <div class="fundPassword_bottom">
        <div class="fundPassword_no" @click="capital_no">取消</div>
        <div class="fundPassword_ok" @click="capital_ok">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBespokeDetail, bespokeDetail } from "@network/bespokeDetail";
import { getMine } from "@network/mine";
export default {
  name: "Customized",
  data() {
    return {
      date: new Date(),
      link: 1,
      setName: "", //设定产品的名字
      setNames: false, //设定产品名字的弹窗
      custom: false, //定制信息的弹窗
      capital: false, //资金密码的弹窗
      valueMoney: "", //定制金额
      password: "", //资金密码
      valueStart: "", //日期开始
      valueEnd: "", //日期结束
      selected_zhe: true, //遮罩层
      selected_tan: true, // 我要定制的弹窗 默认 true

      top0: require("../../assets/img/upl.png"),
      top1: require("../../assets/img/uph.png"),
      down0: require("../../assets/img/downl.png"),
      down1: require("../../assets/img/downh.png"),
      color: "#999999",
      backgroundUrl: require("@/assets/img/danxuan.png"),
      formInline: {
        user: "",
        region: "",
      },

      names: "",
      list: [],
      activeName: "1",
      startDate: "", //开始时间 传递的
      endDate: "", //结束时间 传递的
      page: 1,
      limit: 8,
      count: 0,
      status: 0, //全部/操作中/已清盘
      info: {},
      id: "",

      operator: "", //搜索
      method: "1", //排序方式
      sort: "desc", //

      moneyReg: /^[0-9]*[1-9][0-9]*$/,

      user: {}, //个人信息
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 24 * 60 * 60 * 1000 * 1;
        },
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 24 * 60 * 60 * 1000 * 2;
        },
      },
    };
  },

  created() {
    this.getMine();
  },

  computed: {
    today() {
      var day = new Date();
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000 * 2);
      // var s2 =
      //   day.getFullYear() +
      //   "" +
      //   this.format(day.getMonth() + 1) +
      //   "" +
      //   this.format(day.getDate());
      return day;
    },
    mtoday() {
      var day = new Date();
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000 * 3);
      // var s3 =
      //   day.getFullYear() +
      //   "" +
      //   this.format(day.getMonth() + 1) +
      //   "" +
      //   this.format(day.getDate());
      return day;
    },
  },

  components: {},

  watch: {
    valueMoney(val) {
      this.valueMoney = val.replace(/\D/g, "");
    },
  },
  methods: {
    //资金密码
    capital_no() {
      this.password = "";
      this.setName = "";
      this.capital = false;
      this.selected_zhe = false;
    },
    //确认定制
    capital_ok() {
      this.capital = false;
      this.selected_zhe = false;
      bespokeDetail(
        this.id,
        this.setName,
        this.valueMoney * 100,
        this.password,
        this.formatDate(this.valueStart),
        this.formatDate(this.valueEnd)
      ).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: res.msg,
            offset: 300,
          });
          this.password = "";
          this.setName = "";
          return false;
        }
        this.$message.success({
          message: res.msg,
          offset: 300,
        });
        setTimeout(() => {
          this.$router.push("/vipCenter/myCustom");
        }, 3000);
      });
    },
    //日期格式化
    formatDate(date) {
      let currentDate = "";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      currentDate += year + "-";
      if (month >= 10) {
        currentDate += month + "-";
      } else {
        currentDate += "0" + month + "-";
      }
      if (day >= 10) {
        currentDate += day;
      } else {
        currentDate += "0" + day;
      }
      return currentDate;
    },
    // 定制信息 确定
    determine() {
      this.custom = false;
      this.capital = true;
    },
    close() {
      this.custom = false;
      this.selected_zhe = false;
    },
    // 设定产品名称
    set_no() {
      this.setNames = false;
      this.selected_zhe = false;
    },
    // 设定产品名称 确定
    set_ok() {
      if (this.setName == "") {
        this.$message.error({
          message: "请输入产品名",
          offset: 300,
        });
        return false;
      }

      this.setNames = false;
      this.custom = true;
    },
    // 选定某个定制
    selected(id) {
      console.log(id);
      this.list.forEach((item) => {
        if (item.id == id) {
          this.info = item;
        }
      });
      this.id = id;
      this.selected_zhe = true;
      this.setNames = true;
    },

    // 类型切换
    links1() {
      if (this.valueMoney == "") {
        this.selected_tan = true;
        this.selected_zhe = true;
        return false;
      }
      this.link = 1;
      this.status = 0;
      this.page = 1;
      this.method = 1;
      this.sort = "desc";
      // this.$refs.s2.src = this.down0;
      this.getBespokeDetail();
    },
    links2() {
      this.valueMoney = "";
      this.link = 2;
      this.status = 2;
      this.page = 1;
      this.method = 5;
      this.sort = "asc";
      // this.$refs.c2.src = this.down0;
      this.getBespokeDetail();
    },
    links3() {
      this.valueMoney = "";
      this.link = 3;
      this.status = 3;
      this.page = 1;
      this.method = 5;
      this.sort = "desc";
      // this.$refs.q2.src = this.down0;
      this.getBespokeDetail();
    },
    //处理当前页变动时触发的事件
    handleSizeChange(val) {
      this.page = val;
      this.getBespokeDetail();
    },
    //获取定制
    getBespokeDetail() {
      getBespokeDetail(
        this.page,
        this.limit,
        this.startDate,
        this.endDate,
        this.valueMoney * 100,
        this.status,
        this.formInline.user,
        this.method,
        this.sort
      ).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({
              message: "请重新登录",
              offset: 300,
            });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: res.msg,
            offset: 300,
          });
          return false;
        }

        if (res.count == 0) {
          if (this.link == 2 || this.link == 3) {
            this.formInline.user = "";
            this.$message.error({
              message: "暂无搜索结果，请输入正确的操盘手昵称",
              offset: 300,
            });
            return false;
          }
          this.$message.error({
            message: "没有符合您要求的定制，请重新设置",
            offset: 300,
          });
          return false;
        }
        this.names = res.name;
        this.count = res.count;
        this.list = res.data;
        this.selected_zhe = false;
        this.selected_tan = false;
      });
    },

    //获取个人信息
    getMine() {
      getMine().then((res) => {
        console.log(res);
        // if (res.code == 403) {
        //   this.$message.error({ message: "请重新登录", offset: 300 });
        //   this.$router.replace("/home");
        //   return false;
        // }
        this.user = res.data;
      });
    },

    // 下一步
    next() {
      if (this.endDate == "") {
        this.$message.error({
          message: "请选择结束时间",
          offset: 300,
        });
        return false;
      } else if (this.startDate == "") {
        this.$message.error({
          message: "请选择开始时间",
          offset: 300,
        });
        return false;
      } else if (
        this.valueMoney == "" ||
        parseInt(this.valueMoney) < 50000 ||
        this.valueMoney.slice(0, 1) == 0
      ) {
        this.$message.error({
          message: "请输入定制金额为50000以上的整数",
          offset: 300,
        });
        return false;
      } else if (
        parseInt(this.valueMoney) > parseInt(this.user.balance / 100)
      ) {
        this.$message.error({
          message: "余额不足,请充值",
          offset: 300,
        });
        setTimeout(() => {
          this.$router.push("/vipCenter/recharge");
        }, 1500);
        return false;
      }
      this.getBespokeDetail();
    },
    //金额格式化
    formatValue(val) {
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.valueMoney = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.valueMoney = val;
    },
    //日期加0
    format(num) {
      if (num < 10) return "0" + num;
      return num;
    },

    //选择开始时间
    chooseStart() {
      console.log(this.valueStart);
      // if(this.valueStart<this.today){
      //   this.$message.error({
      //     message:'请选择今日两日之后的日期',
      //     offset:300
      //   });
      //   this.valueStart='';
      //   return false;
      // }
      this.startDate =
        new Date(this.valueStart).getFullYear() +
        "-" +
        this.format(new Date(this.valueStart).getMonth() + 1) +
        "-" +
        this.format(new Date(this.valueStart).getDate());
    },
    //选择结束时间
    chooseEnd() {
      console.log(this.valueEnd);
      this.endDate =
        new Date(this.valueEnd).getFullYear() +
        "-" +
        this.format(new Date(this.valueEnd).getMonth() + 1) +
        "-" +
        this.format(new Date(this.valueEnd).getDate());
    },

    //定制1  排序
    years1() {
      if (this.method == 1) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s1.src = this.top0;
          this.$refs.s2.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s1.src = this.top1;
          this.$refs.s2.src = this.down0;
        }
      } else {
        this.$refs.s1.src = this.top0;
        this.$refs.s2.src = this.down1;
        this.$refs.s3.src = this.top1;
        this.$refs.s4.src = this.down1;
        this.sort = "desc";
        this.method = 1;
      }
      this.getBespokeDetail();
    },
    fengxian() {
      if (this.method == 2) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s3.src = this.top0;
          this.$refs.s4.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s3.src = this.top1;
          this.$refs.s4.src = this.down0;
        }
      } else {
        this.$refs.s1.src = this.top1;
        this.$refs.s2.src = this.down1;
        this.$refs.s3.src = this.top1;
        this.$refs.s4.src = this.down0;
        this.sort = "asc";
        this.method = 2;
      }
      this.getBespokeDetail();
    },

    //定制2  排序
    years2() {
      if (this.method == 1) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.c1.src = this.top0;
          this.$refs.c2.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.c1.src = this.top1;
          this.$refs.c2.src = this.down0;
        }
      } else {
        this.$refs.c1.src = this.top1;
        this.$refs.c2.src = this.down0;
        this.$refs.c3.src = this.top1;
        this.$refs.c4.src = this.down1;
        this.$refs.c5.src = this.top1;
        this.$refs.c6.src = this.down1;
        this.$refs.c7.src = this.top1;
        this.$refs.c8.src = this.down1;
        this.sort = "asc";
        this.method = 1;
      }
      this.getBespokeDetail();
    },
    jingzhi1() {
      if (this.method == 3) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.c3.src = this.top0;
          this.$refs.c4.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.c3.src = this.top1;
          this.$refs.c4.src = this.down0;
        }
      } else {
        this.$refs.c1.src = this.top1;
        this.$refs.c2.src = this.down1;
        this.$refs.c3.src = this.top1;
        this.$refs.c4.src = this.down0;
        this.$refs.c5.src = this.top1;
        this.$refs.c6.src = this.down1;
        this.$refs.c7.src = this.top1;
        this.$refs.c8.src = this.down1;
        this.sort = "asc";
        this.method = 3;
      }
      this.getBespokeDetail();
    },
    jine1() {
      if (this.method == 4) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.c5.src = this.top0;
          this.$refs.c6.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.c5.src = this.top1;
          this.$refs.c6.src = this.down0;
        }
      } else {
        this.$refs.c1.src = this.top1;
        this.$refs.c2.src = this.down1;
        this.$refs.c3.src = this.top1;
        this.$refs.c4.src = this.down1;
        this.$refs.c5.src = this.top1;
        this.$refs.c6.src = this.down0;
        this.$refs.c7.src = this.top1;
        this.$refs.c8.src = this.down1;
        this.sort = "asc";
        this.method = 4;
      }
      this.getBespokeDetail();
    },
    dq() {
      if (this.method == 5) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.c7.src = this.top0;
          this.$refs.c8.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.c7.src = this.top1;
          this.$refs.c8.src = this.down0;
        }
      } else {
        this.$refs.c1.src = this.top1;
        this.$refs.c2.src = this.down1;
        this.$refs.c3.src = this.top1;
        this.$refs.c4.src = this.down1;
        this.$refs.c5.src = this.top1;
        this.$refs.c6.src = this.down1;
        this.$refs.c7.src = this.top1;
        this.$refs.c8.src = this.down0;
        this.sort = "asc";
        this.method = 5;
      }
      this.getBespokeDetail();
    },
    //定制3  排序
    years3() {
      if (this.method == 1) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.q1.src = this.top0;
          this.$refs.q2.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.q1.src = this.top1;
          this.$refs.q2.src = this.down0;
        }
      } else {
        this.$refs.q1.src = this.top1;
        this.$refs.q2.src = this.down0;
        this.$refs.q3.src = this.top1;
        this.$refs.q4.src = this.down1;
        this.$refs.q5.src = this.top1;
        this.$refs.q6.src = this.down1;
        this.$refs.q7.src = this.top1;
        this.$refs.q8.src = this.down1;
        this.sort = "asc";
        this.method = 1;
      }
      this.getBespokeDetail();
    },
    jingzhi2() {
      if (this.method == 3) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.q3.src = this.top0;
          this.$refs.q4.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.q3.src = this.top1;
          this.$refs.q4.src = this.down0;
        }
      } else {
        this.$refs.q1.src = this.top1;
        this.$refs.q2.src = this.down1;
        this.$refs.q3.src = this.top1;
        this.$refs.q4.src = this.down0;
        this.$refs.q5.src = this.top1;
        this.$refs.q6.src = this.down1;
        this.$refs.q7.src = this.top1;
        this.$refs.q8.src = this.down1;
        this.sort = "asc";
        this.method = 3;
      }
      this.getBespokeDetail();
    },
    jine2() {
      if (this.method == 4) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.q5.src = this.top0;
          this.$refs.q6.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.q5.src = this.top1;
          this.$refs.q6.src = this.down0;
        }
      } else {
        this.$refs.q1.src = this.top1;
        this.$refs.q2.src = this.down1;
        this.$refs.q3.src = this.top1;
        this.$refs.q4.src = this.down1;
        this.$refs.q5.src = this.top1;
        this.$refs.q6.src = this.down0;
        this.$refs.q7.src = this.top1;
        this.$refs.q8.src = this.down1;
        this.sort = "asc";
        this.method = 4;
      }
      this.getBespokeDetail();
    },
    qb() {
      if (this.method == 5) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.q7.src = this.top0;
          this.$refs.q8.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.q7.src = this.top1;
          this.$refs.q8.src = this.down0;
        }
      } else {
        this.$refs.q1.src = this.top1;
        this.$refs.q2.src = this.down1;
        this.$refs.q3.src = this.top1;
        this.$refs.q4.src = this.down1;
        this.$refs.q5.src = this.top1;
        this.$refs.q6.src = this.down1;
        this.$refs.q7.src = this.top0;
        this.$refs.q8.src = this.down1;
        this.sort = "asc";
        this.method = 5;
      }
      this.getBespokeDetail();
    },

    btn_ru(id) {
      console.log(id);
    },

    radio(id) {
      this.currentIndex = id;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    onSubmit() {
      console.log("submit!");
      this.getBespokeDetail();
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../assets/css/customized/customized.less";
</style>
